



















































































































































import Component from 'vue-class-component'
import ExpandCollapse from "@/components/animations/ExpandCollapse.vue";
import UserPresentation from "@/components/UserPresentation.vue";
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import { Statues } from "@/shared/interfaces/Statues";
import SygniArrowButton from "@/components/buttons/SygniArrowButton.vue";
import {BSpinner, BvTableField, BTooltip} from "bootstrap-vue";
import SygniCircleButton from "@/components/buttons/SygniCircleStatusButton.vue";
import InvestorsInnerTable from "@/modules/genprox/modules/fund/modules/capital-rise/components/InvestorsInnerTable.vue";
import {mapState} from "vuex";
import {Investor} from "@/modules/genprox/modules/fund/modules/capital-rise/store/types";
import SygniTableLegacy from "@/components/table/SygniTableLegacy.vue";
import breakpoints from '@/plugins/breakpoints';
import OwnerModal from '@/modules/genprox/modules/fund/modules/capital-rise/components/OwnerModal.vue';
import { Watch } from 'vue-property-decorator';
import { create, all } from 'mathjs'
import SygniPagination from '@/components/table/SygniPagination.vue';
import Vue from 'vue';
import _ from 'lodash';
const math = create(all);

@Component({
  components: {
    SygniCircleButton,
    InvestorsInnerTable, SygniArrowButton, SygniRoundedButton, UserPresentation, ExpandCollapse, BSpinner, OwnerModal, BTooltip },
    computed: mapState('investors', {
      itemsLength: (state: any) => state.investorsLength,
    }),
})
export default class InvestorsTable extends SygniTableLegacy<MarketplaceItem> {
  breakpoints = breakpoints;
  openedOwner: any = null;
  
  tableFields: (BvTableField & {borderless?: Boolean} & { key: string })[] = [
    { key: 'name', sortable: true, label: 'Investor', thClass: 'th-cell' },
    { key: 'status', sortable: true, borderless: true, thClass: 'th-cell' },
    { key: 'sourceName', sortable: true, borderless: true , label: 'source', thClass: 'th-cell' },
    { key: 'ownerName', sortable: true, borderless: true , label: 'owner', thClass: 'th-cell' },
    { key: 'autentiStatuses', label: 'E-signature statuses', sortable: false, thClass: 'th-cell' },
    { key: 'created', sortable: true, thClass: 'th-cell' },
    { key: 'productStatuses', sortable: false, thClass: 'th-cell' },
    { key: 'totalInvestmentValue', sortable: false, thClass: 'th-cell' },
  ];

  get activeUserData() {
    return this.$store.getters['genprox/activeUserData'];
  }

  get isMarketplaceAdmin() {
    return this.activeUserData?.role?.marketplace === 'admin' ? true : false;
  }

  get isMarketplaceHead() {
    return this.activeUserData?.role?.marketplace === 'head' ? true : false;
  }

  get isMarketplaceAccountant() {
    return this.activeUserData?.role?.marketplace === 'accountant' ? true : false;
  }

  get addProductEnabled() {
    if (this.isMarketplaceAdmin || this.isMarketplaceHead) {
      return true;
    }

    if (this.activeUserData?.role['broker-role']) {
      return this.activeUserData?.role['broker-role'].includes('add-product') ? true : false;
    }

    return false;
  }

  get totals() {
    let totals: any = {};

    this.items.forEach((item: any) => {
      const investmentValues = item?.investmentValues || []

      if (investmentValues?.length) {
        investmentValues.forEach((el: any) => {
          if (el.currency) {
            if (totals[el.currency]) {
              totals[el.currency] = el.value ? math.number(math.add(math.bignumber(totals[el.currency]), math.bignumber(el.value))) : math.number(math.add(math.bignumber(totals[el.currency]), 0));
            } else {
              totals[el.currency] = el.value ? math.bignumber(math.bignumber(el.value)) : 0;
            }
          }
        })
      } else {
        if (item.totalInvestmentValue.currency) {
          if (totals[item.totalInvestmentValue.currency]) {
            totals[item.totalInvestmentValue.currency] = item.totalInvestmentValue.value ? math.number(math.add(math.bignumber(totals[item.totalInvestmentValue.currency]), math.bignumber(item.totalInvestmentValue.value))) : math.number(math.add(math.bignumber(totals[item.totalInvestmentValue.currency]), 0));
          } else {
            totals[item.totalInvestmentValue.currency] = item.totalInvestmentValue.value ? math.bignumber(math.bignumber(item.totalInvestmentValue.value)) : 0;
          }
        }
      }
    })

    return totals;
  }

  getAutentiStatuses(statuses: string[]) {
    const filteredStatuses: string[] = statuses?.filter((el: string) => el?.toUpperCase() === 'PROCESSING' || el?.toUpperCase() === 'DRAFT' || el?.toUpperCase() === 'COMPLETED' || el?.toUpperCase() === 'ERROR')

    if (filteredStatuses?.length !== statuses?.length) {
      filteredStatuses.push('NEW')
    }

    return _.uniq(filteredStatuses)
  }

  showInvestmentValues(item: any) {
    const currency = item?.totalInvestmentValue?.currency
    const investmentValues = item?.investmentValues?.filter((el: any) => el?.currency !== currency) || []

    return investmentValues?.length ? true : false
  }

  getInvestmentValues(item: any) {
    const investmentValues = item?.investmentValues || []

    return investmentValues
  }

  rowClickHandler() {
    const detailsBtn = ((this.$refs.investorsTable as InvestorsTable).$el as HTMLTableElement).querySelector(`tbody tr:focus .arrow`);
    if(detailsBtn) {
      (detailsBtn as HTMLDivElement).click();
    }
  }

  openOwnerModal(item: any) {
    this.openedOwner = item
  }

  closeOwnerModal() {
    this.openedOwner = null
  }

  onSortChange(): void {
    this.$store.commit(this.setTableBusyMutation, true);
    setTimeout(() => {
      const sign: string = this.sortDesc ? '-' : '';
      let sortBy: string = '';
      switch(this.sortBy){
        case('name'):
          sortBy = 'investmentClientName';
          break;
        case('status'):
          sortBy = 'investmentClientStatus';
          break;
        case('sourceName'):
          sortBy = 'investmentClientSourceName';
          break;
        case('created'):
          sortBy = 'investmentClientCreated';
          break;
        case('ownerName'):
          sortBy = 'investmentClientOwnerName';
          break;  
      }
      this.sortingQuery = `sort[]=${sign}${sortBy}`;
      this.$store.commit(this.sortingQueryAction, {sorting: this.sortingQuery});
      this.$store.dispatch(this.getItemsAction)
    },1)
  }

  async openDetails(rowData: any){
    this.$store.commit(this.setTableBusyMutation, true);
    rowData.item.details = await this.$store.dispatch('investors/getInvestorProducts', rowData.item.id);
    this.$store.commit(this.setTableBusyMutation, false);
    rowData.toggleDetails();
    this.setWidths();
  }

  statusClass(status: Statues) {
    switch(status.toUpperCase()) {
      case(Statues.new): return 'black';
      case(Statues.active): return 'primary';
      case(Statues.invited): return 'success';
      case(Statues.awaiting): return 'success';
      case(Statues.pending): return 'black';
      case(Statues.rejected): return 'danger';
      case(Statues.closed): return 'danger';
      case(Statues.declined): return 'disabled';
      
    }
    return '';
  }

  goToProfile(investor: Investor): void {
    this.$router.push({name: 'profile-dashboard-guest-investor', params: { id: investor.id}})
  }

  constructor() {  // PROTOTYPE COMPONENT
    super();
  }

  async refreshTable() {
    this.$store.commit('investors/setInvestorsTableBusy', true)
    await this.$store.dispatch('investors/getInvestors')
  }

  mounted(){
    this.onMounted();
    this.setBorderlessTds();
  }

  openAddNewProductForm(id: string): void {
    this.$store.commit('investors/clearAnnexingData');
    this.$store.commit('investors/setNewProductInvestor', id);
    if (this.$route.path.includes('company')) {
      this.$router.push({ name: 'addNewProductStepOne', path: '/company/capital-rise/product/new/step-one', params: { investorId: id } });
    } else {
      this.$router.push({ name: 'addNewProductStepOne', path: '/fund/capital-rise/product/new/step-one', params: { investorId: id } });
    }
  }

  setWidths() {
    const columns: Array<number> = [];
    (this.$refs.investorsTable as InvestorsTable).$el.querySelectorAll('thead tr th.th-cell').forEach((column: any) => {
      columns.push(column.offsetWidth);
    });

    this.$nextTick(() => {
      const rows = [
        (this.$refs.investorsTable as InvestorsTable).$el.querySelectorAll('.b-table-details thead tr'),
        (this.$refs.investorsTable as InvestorsTable).$el.querySelectorAll('.b-table-details tbody tr')
      ];

      rows.forEach((dataRows: any) => {
        dataRows.forEach((dataRow: any) => {
          columns.forEach((width: any, index: number) => {
            const element: HTMLElement = dataRow.querySelector(`th:nth-of-type(${index + 1})`) ? dataRow.querySelector(`th:nth-of-type(${index + 2})`) : dataRow.querySelector(`td:nth-of-type(${index + 2})`);

            if (index === 0) {
              element.style.width = `${width - 50}px`;
              element.style.maxWidth = `${width - 50}px`;
            } else {
              element.style.width = `${(index + 2) == columns.length ? width - 10 : width}px`;
              element.style.maxWidth = `${(index + 2) == columns.length ? width - 10 : width}px`;
            }

          })
        })
      })
    })
  }

  addPagination(): void {
    const ComponentClass = Vue.extend(SygniPagination);
    this.paginationInstance = new ComponentClass<SygniPagination>();
    this.paginationInstance.$mount();
    this.paginationInstance.showPageNumberOptions = true;
    this.paginationInstance.perPage = this.perPage || 10;
    this.paginationInstance.totalRows = this.itemsLength;
    
    if (this.getQuery) {
      this.$nextTick(() => {
        const query = this.$store.getters[this.getQuery]
        const params = new URLSearchParams(query?.limit)
        const offset = Number(params.get('offset')) || 0
        const limit = Number(params.get('limit')) || this.perPage || 10
        
        const currentPage = (offset / limit) + 1
        this.paginationInstance.currentPage = currentPage
      })
    }

    this.paginationInstance.$on('change', (currentPage: number) => {
      // this.currentPage = currentPage;
      this.$store.commit(this.setTableBusyMutation, true);
      this.$store.commit(this.sortingQueryAction, { limit: this.createLimitQuery(currentPage, this.perPage || 10) });
      this.$store.dispatch(this.getItemsAction);
    })
    this.paginationInstance.$on('changePerPageOption', (perPage: number) => {
      if (this.tablePerPageMutation) this.$store.commit(this.tablePerPageMutation, perPage);
      this.$store.commit(this.setTableBusyMutation, true);
      this.currentPage = 1
      this.paginationInstance.perPage = perPage
      // this.localTableQuery = new TableQuery().setQuery(this.tableData.query);
      // this.localTableQuery.limit = this.tableData.perPage;
      this.$store.commit(this.sortingQueryAction, { limit: this.createLimitQuery(1, perPage || 10) });
      // this.localTableQuery.offset = 0;
      // this.setQuery();
      this.$store.dispatch(this.getItemsAction)
      this.changePerPageOption(perPage || 10);
    });
    const tableElement: Element = this.$el.querySelector('.sygni-b-table') as Element;
    this.$el.insertBefore(this.paginationInstance.$el as Node, tableElement.nextSibling);
  }

  changePerPageOption(perPage: number): void {
    this.$emit('changePerPageOption', perPage);
  }

  @Watch('breakpoints.w') onBreakpointsChange(): void {
    this.setWidths();
  }
}

export interface MarketplaceItem {
}

