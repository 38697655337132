var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "clat-tax-table"
  }, [_c('div', {
    staticClass: "clat-tax-table__inner"
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.selectedRowsLength > 0,
      expression: "selectedRowsLength > 0"
    }],
    staticClass: "bulk-options",
    style: "margin-top: ".concat(_vm.bulkOptionsMarginTop, "px;")
  }, [_c('sygni-loader', {
    attrs: {
      "is-loading": _vm.isPaymentStatusLoading || _vm.isAccountingDateLoading || _vm.isCsvExporting
    }
  }), _c('div', {
    staticClass: "bulk-options__row"
  }, [_c('span', {
    staticClass: "bulk-options__label"
  }, [_vm._v("Selected: " + _vm._s(_vm.selectedRowsLength))]), _c('div', {
    staticClass: "bulk-options__actions"
  }, [_c('sygni-rect-button', {
    staticClass: "bulk-options__action outline disabled",
    on: {
      "click": _vm.clearTableSelection
    }
  }, [_vm._v("Unselect all")]), _c('div', {
    class: ['dropdown', _vm.accountingDateOpened ? 'dropdown--opened' : '']
  }, [_c('div', {
    staticClass: "dropdown__placeholder"
  }, [_c('p', {
    staticClass: "dropdown__placeholder-text"
  }, [_vm._v("Actions")]), _c('div', {
    staticClass: "dropdown__placeholder-icon"
  }, [_c('img', {
    attrs: {
      "src": _vm._f("getIcon")('ARROW'),
      "alt": "Dropdown"
    }
  })])]), _c('div', {
    staticClass: "dropdown__inner"
  }, [_vm.isPaymentManager ? _c('div', {
    staticClass: "dropdown__option dropdown__option--block"
  }, [_c('div', {
    staticClass: "dropdown__option-text"
  }, [_vm._v("Set payment status")]), _c('sygni-select', {
    attrs: {
      "options": _vm.paymentStatuses,
      "placeholder": _vm.bulkPaymentStatusPlaceholder
    },
    model: {
      value: _vm.bulkPaymentStatus,
      callback: function callback($$v) {
        _vm.bulkPaymentStatus = $$v;
      },
      expression: "bulkPaymentStatus"
    }
  })], 1) : _vm._e(), _vm.isPaymentManager ? _c('div', {
    staticClass: "dropdown__option dropdown__option--block"
  }, [_c('div', {
    staticClass: "dropdown__option-text"
  }, [_vm._v("Set payment date")]), _c('sygni-date-picker', {
    staticClass: "border-collapse",
    attrs: {
      "label": ""
    },
    on: {
      "open": _vm.openAccountingDateModal,
      "close": _vm.closeAccountingDateModal
    },
    model: {
      value: _vm.accountingDate,
      callback: function callback($$v) {
        _vm.accountingDate = $$v;
      },
      expression: "accountingDate"
    }
  })], 1) : _vm._e(), _vm.hasOnlyValidDeclarationOptions ? _c('div', {
    staticClass: "dropdown__option",
    on: {
      "click": function click($event) {
        return _vm.openClatTaxModal('bulk', null);
      }
    }
  }, [_c('p', {
    staticClass: "dropdown__option-text"
  }, [_vm._v("Generate declaration")]), _c('div', {
    staticClass: "dropdown__option-icon"
  }, [_c('img', {
    attrs: {
      "src": _vm._f("getIcon")('UPLOAD'),
      "alt": "Generate declaration"
    }
  })])]) : _vm._e(), _vm.isPaymentManager ? _c('div', {
    staticClass: "dropdown__option",
    on: {
      "click": function click($event) {
        return _vm.enableBankAccountModal();
      }
    }
  }, [_c('p', {
    staticClass: "dropdown__option-text"
  }, [_vm._v("Generate payment package")]), _c('div', {
    staticClass: "dropdown__option-icon"
  }, [_c('img', {
    attrs: {
      "src": _vm._f("getIcon")('WALLET'),
      "alt": "Generate payment package"
    }
  })])]) : _vm._e(), !_vm.isPaymentManager && !_vm.hasOnlyValidDeclarationOptions ? _c('div', {
    staticClass: "dropdown__option"
  }, [_c('p', {
    staticClass: "dropdown__option-text"
  }, [_vm._v("No actions available")])]) : _vm._e()])])], 1)])], 1), _c('b-table', _vm._g(_vm._b({
    ref: "paymentsTable",
    staticClass: "sygni-b-table",
    attrs: {
      "fields": _vm.tableFieldsComputed,
      "sort-by": _vm.sortBy,
      "sort-desc": _vm.sortDesc,
      "show-empty": true,
      "no-local-sorting": true,
      "per-page": _vm.perPage,
      "busy": _vm.isBusy
    },
    on: {
      "sort-changed": _vm.onSortChange,
      "update:sortBy": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sortDesc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      },
      "update:sort-desc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "head(selected)",
      fn: function fn() {
        return [_c('sygni-checkbox', {
          staticClass: "secondary",
          model: {
            value: _vm.selectAllRowsBoolean,
            callback: function callback($$v) {
              _vm.selectAllRowsBoolean = $$v;
            },
            expression: "selectAllRowsBoolean"
          }
        })];
      },
      proxy: true
    }, _vm._l(_vm.$scopedSlots, function (_, slot) {
      return {
        key: slot,
        fn: function fn(rowData) {
          return [_vm._t(slot, null, null, rowData)];
        }
      };
    }), {
      key: "cell(selected)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "table-checkbox",
          on: {
            "click": function click($event) {
              $event.stopPropagation();
              return _vm.toggleTableRow(_vm.bulkRows[rowData === null || rowData === void 0 ? void 0 : rowData.index], rowData === null || rowData === void 0 ? void 0 : rowData.index);
            }
          }
        }, [_c('sygni-checkbox', {
          staticClass: "secondary",
          model: {
            value: _vm.bulkRows[rowData.index].value,
            callback: function callback($$v) {
              _vm.$set(_vm.bulkRows[rowData.index], "value", $$v);
            },
            expression: "bulkRows[rowData.index].value"
          }
        })], 1)];
      }
    }, {
      key: "cell(productCode)",
      fn: function fn(rowData) {
        var _rowData$item;

        return [_c('div', {
          staticClass: "code",
          on: {
            "click": function click($event) {
              return _vm.goToProductSummary(rowData.item);
            }
          }
        }, [_vm._v(" " + _vm._s((_rowData$item = rowData.item) !== null && _rowData$item !== void 0 && _rowData$item.productCode ? rowData.item.productCode : 'NONE') + " ")])];
      }
    }, {
      key: "cell(investor)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "investor-name"
        }, [_c('span', {
          staticClass: "text-nowrap",
          on: {
            "click": function click($event) {
              return _vm.goToProfile(rowData.item);
            }
          }
        }, [_vm._v(_vm._s(rowData.item.investor))])])];
      }
    }, {
      key: "cell(productType)",
      fn: function fn(rowData) {
        return [_vm._v(" " + _vm._s(_vm._f("snakeCaseToTitleCase")(rowData.item.productType)) + " ")];
      }
    }, {
      key: "cell(paymentDeadline)",
      fn: function fn(rowData) {
        return [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(_vm._s(_vm._f("dateWithoutTime")(rowData.item.paymentDeadline)))])];
      }
    }, {
      key: "cell(value)",
      fn: function fn(rowData) {
        var _rowData$item2;

        return [_c('div', {
          staticClass: "text-right"
        }, [_vm._v(" " + _vm._s(_vm._f("numberFormat")(rowData.item.value)) + " " + _vm._s(rowData !== null && rowData !== void 0 && (_rowData$item2 = rowData.item) !== null && _rowData$item2 !== void 0 && _rowData$item2.currency ? rowData.item.currency : 'PLN') + " ")])];
      }
    }, {
      key: "cell(statementGenerated)",
      fn: function fn(rowData) {
        var _rowData$item3;

        return [_c('div', {
          staticClass: "text-center"
        }, [_vm._v(" " + _vm._s((_rowData$item3 = rowData.item) !== null && _rowData$item3 !== void 0 && _rowData$item3.statementGenerated ? 'YES' : 'NO') + " ")])];
      }
    }, {
      key: "cell(paymentDate)",
      fn: function fn(rowData) {
        return [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(_vm._s(_vm._f("dateWithoutTime")(rowData.item.paymentDate)))])];
      }
    }, {
      key: "cell(paymentStatus)",
      fn: function fn(rowData) {
        return [_c('div', {
          staticClass: "table__status d-flex justify-content-center align-items-center"
        }, [_c('sygni-rounded-button', {
          class: "filled round ".concat(_vm.statusClass(rowData.item.paymentStatus)),
          attrs: {
            "hoverable": false
          }
        }, [_vm._v(" " + _vm._s(_vm._f("capitalizeFirstLetter")(rowData.item.paymentStatus)) + " ")])], 1)];
      }
    }, {
      key: "cell(actions)",
      fn: function fn(rowData) {
        var _rowData$item4, _rowData$item5, _rowData$item6;

        return [_c('div', {
          staticClass: "td__actions",
          on: {
            "click": function click($event) {
              $event.stopPropagation();
            }
          }
        }, [_c('div', {
          staticClass: "td__actions-elements"
        }, [_c('div', {
          staticClass: "action"
        }, [_c('div', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            value: _vm.isPaymentManager && !_vm.isMarketplaceAccountant ? 'Edit details' : 'Show details',
            expression: "isPaymentManager && !isMarketplaceAccountant ? 'Edit details' : 'Show details'",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "action__el",
          on: {
            "click": function click($event) {
              return _vm.openDetailsModal(rowData.item.id);
            }
          }
        }, [_c('img', {
          attrs: {
            "src": _vm._f("getIcon")(_vm.isPaymentManager && !_vm.isMarketplaceAccountant ? 'EDIT' : 'Fund')
          }
        })])]), _vm.isClatTaxGenerationEnabled(rowData === null || rowData === void 0 ? void 0 : rowData.item) ? _c('div', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            value: _vm.clatTaxGenerationTooltipText(rowData === null || rowData === void 0 ? void 0 : rowData.item),
            expression: "clatTaxGenerationTooltipText(rowData?.item)",
            modifiers: {
              "hover": true
            }
          }],
          class: ['action', !_vm.isClatTaxGenerationEnabled(rowData === null || rowData === void 0 ? void 0 : rowData.item) ? 'action--disabled' : ''],
          on: {
            "click": function click($event) {
              return _vm.openClatTaxModal('single', rowData.item);
            }
          }
        }, [_c('div', {
          staticClass: "action__el"
        }, [_c('img', {
          attrs: {
            "src": _vm._f("getIcon")('UPLOAD')
          }
        })])]) : _vm._e(), rowData !== null && rowData !== void 0 && (_rowData$item4 = rowData.item) !== null && _rowData$item4 !== void 0 && _rowData$item4.statementGenerated ? _c('div', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            value: rowData !== null && rowData !== void 0 && (_rowData$item5 = rowData.item) !== null && _rowData$item5 !== void 0 && _rowData$item5.statementGenerated ? 'See generated declarations' : '',
            expression: "rowData?.item?.statementGenerated ? 'See generated declarations' : ''",
            modifiers: {
              "hover": true
            }
          }],
          class: ['action', !(rowData !== null && rowData !== void 0 && (_rowData$item6 = rowData.item) !== null && _rowData$item6 !== void 0 && _rowData$item6.statementGenerated) ? 'action--disabled' : ''],
          on: {
            "click": function click($event) {
              return _vm.loadGeneratedDeclarations(rowData.item);
            }
          }
        }, [_c('div', {
          staticClass: "action__el"
        }, [_c('img', {
          attrs: {
            "src": _vm._f("getIcon")('Fund'),
            "alt": ""
          }
        })])]) : _vm._e(), _vm.isPaymentManager ? _c('div', {
          staticClass: "action",
          on: {
            "click": function click($event) {
              return _vm.enableBankAccountModal(rowData.item);
            }
          }
        }, [_c('div', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            value: 'Generate payment package',
            expression: "'Generate payment package'",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "action__el"
        }, [_c('img', {
          attrs: {
            "src": _vm._f("getIcon")('WALLET')
          }
        })])]) : _vm._e()]), _c('div', {
          staticClass: "td__actions-toggle",
          on: {
            "click": function click($event) {
              $event.stopPropagation();
            }
          }
        }, [_c('div', {
          staticClass: "action"
        }, [_c('div', {
          staticClass: "action__el"
        }, [_c('img', {
          attrs: {
            "src": _vm._f("getIcon")('DOTS')
          }
        })])])])])];
      }
    }, {
      key: "custom-foot",
      fn: function fn(data) {
        return [_c('tr', _vm._l(data.columns, function (i) {
          var _data$fields, _data$fields2, _data$fields3, _data$fields4;

          return _c('td', {
            key: i,
            class: "td-".concat(data.fields[i - 1].key)
          }, [((_data$fields = data.fields[i - 1]) === null || _data$fields === void 0 ? void 0 : _data$fields.key) == 'productCode' ? _c('div', [_c('p', [_vm._v("Total payments:")])]) : _vm._e(), ((_data$fields2 = data.fields[i - 1]) === null || _data$fields2 === void 0 ? void 0 : _data$fields2.key) == 'investor' ? _c('div', [_c('p', [_vm._v(_vm._s(_vm.items.length ? _vm.items.length : 0))])]) : _vm._e(), ((_data$fields3 = data.fields[i - 1]) === null || _data$fields3 === void 0 ? void 0 : _data$fields3.key) == 'statementGenerated' ? _c('div', [_c('p', [_vm._v("Total amount:")])]) : _vm._e(), ((_data$fields4 = data.fields[i - 1]) === null || _data$fields4 === void 0 ? void 0 : _data$fields4.key) == 'value' ? _c('div', {
            staticClass: "text-right text-nowrap"
          }, _vm._l(Object.entries(_vm.totals), function (_ref) {
            var key = _ref[0],
                value = _ref[1];
            return _c('p', {
              key: key
            }, [_vm._v(_vm._s(_vm._f("numberFormat")(value, 2)) + " " + _vm._s(key))]);
          }), 0) : _vm._e()]);
        }), 0)];
      }
    }, {
      key: "empty",
      fn: function fn() {
        return [_c('div', {
          staticClass: "cell cell--empty"
        }, [_vm._v("There are no items on your list")])];
      },
      proxy: true
    }], null, true)
  }, 'b-table', Object.assign({}, _vm.$props, _vm.$attrs), false), _vm.$listeners)), _vm.showBankAccountModal ? _c('sygni-modal', {
    staticClass: "remove-overflow-y",
    attrs: {
      "isLoading": _vm.isClatTaxPaymentPackageLoading,
      "header": "Payment Package Details",
      "cancelText": "Cancel",
      "confirmText": "Generate package",
      "size": "large"
    },
    on: {
      "cancel": _vm.closeBankAccountModal,
      "confirm": _vm.generatePaymentPackage,
      "close": _vm.closeBankAccountModal
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        var _vm$$v, _vm$$v$clatTaxPackage, _vm$$v2, _vm$$v2$clatTaxPackag, _vm$$v3, _vm$$v3$clatTaxPackag, _vm$selectedBankAccou, _vm$selectedBankAccou2;

        return [_c('div', {
          staticClass: "sygni-modal-form"
        }, [_c('p', {
          staticClass: "sygni-modal-form__header"
        }, [_vm._v("1. Enter payment package name:")]), _c('div', {
          staticClass: "sygni-modal-form__group"
        }, [_c('div', {
          staticClass: "sygni-modal-form__inputs"
        }, [_c('sygni-input', {
          attrs: {
            "label": "",
            "placeholder": _vm.defaultPaymentPackageName
          },
          model: {
            value: _vm.clatTaxPackageModal.customPackageName,
            callback: function callback($$v) {
              _vm.$set(_vm.clatTaxPackageModal, "customPackageName", $$v);
            },
            expression: "clatTaxPackageModal.customPackageName"
          }
        })], 1)])]), _c('div', {
          staticClass: "sygni-modal-form"
        }, [_c('p', {
          staticClass: "sygni-modal-form__header"
        }, [_vm._v("2. Execute payment package:")]), _c('div', {
          staticClass: "sygni-modal-form__group"
        }, [_c('div', {
          staticClass: "sygni-modal-form__radios"
        }, [_c('sygni-radio', {
          attrs: {
            "group": 'clatTaxCustomPaymentDate',
            "radioValue": true,
            "label": "on a custom date"
          },
          model: {
            value: _vm.useCustomClatTaxPaymentDate,
            callback: function callback($$v) {
              _vm.useCustomClatTaxPaymentDate = $$v;
            },
            expression: "useCustomClatTaxPaymentDate"
          }
        }), _c('sygni-radio', {
          attrs: {
            "group": 'clatTaxCustomPaymentDate',
            "radioValue": false,
            "label": "at date of payment of each document"
          },
          model: {
            value: _vm.useCustomClatTaxPaymentDate,
            callback: function callback($$v) {
              _vm.useCustomClatTaxPaymentDate = $$v;
            },
            expression: "useCustomClatTaxPaymentDate"
          }
        })], 1), _c('sygni-date-picker', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.useCustomClatTaxPaymentDate,
            expression: "useCustomClatTaxPaymentDate"
          }],
          attrs: {
            "format": "YYYY-MM-DD",
            "validation": (_vm$$v = _vm.$v) === null || _vm$$v === void 0 ? void 0 : (_vm$$v$clatTaxPackage = _vm$$v.clatTaxPackageModal) === null || _vm$$v$clatTaxPackage === void 0 ? void 0 : _vm$$v$clatTaxPackage.customPaymentDate
          },
          model: {
            value: _vm.clatTaxPackageModal.customPaymentDate,
            callback: function callback($$v) {
              _vm.$set(_vm.clatTaxPackageModal, "customPaymentDate", $$v);
            },
            expression: "clatTaxPackageModal.customPaymentDate"
          }
        })], 1)]), _c('div', {
          staticClass: "sygni-modal-form"
        }, [_c('p', {
          staticClass: "sygni-modal-form__header"
        }, [_vm._v("3. Select Tax Office:")]), _c('div', {
          staticClass: "sygni-modal-form__group"
        }, [_c('div', {
          staticClass: "sygni-modal-form__inputs"
        }, [_c('sygni-select', {
          attrs: {
            "label": "",
            "options": _vm.taxOfficeCodes,
            "searchable": true,
            "validation": (_vm$$v2 = _vm.$v) === null || _vm$$v2 === void 0 ? void 0 : (_vm$$v2$clatTaxPackag = _vm$$v2.clatTaxPackageModal) === null || _vm$$v2$clatTaxPackag === void 0 ? void 0 : _vm$$v2$clatTaxPackag.taxOffice
          },
          model: {
            value: _vm.clatTaxPackageModal.taxOffice,
            callback: function callback($$v) {
              _vm.$set(_vm.clatTaxPackageModal, "taxOffice", $$v);
            },
            expression: "clatTaxPackageModal.taxOffice"
          }
        })], 1)])]), _c('div', {
          staticClass: "sygni-modal-form"
        }, [_c('p', {
          staticClass: "sygni-modal-form__header"
        }, [_vm._v("4. Select Your Bank Account:")]), _c('div', {
          staticClass: "sygni-modal-form__group"
        }, [_c('div', {
          staticClass: "sygni-modal-form__inputs"
        }, [_c('sygni-select', {
          attrs: {
            "label": "",
            "options": _vm.bankAccountOptions,
            "validation": (_vm$$v3 = _vm.$v) === null || _vm$$v3 === void 0 ? void 0 : (_vm$$v3$clatTaxPackag = _vm$$v3.clatTaxPackageModal) === null || _vm$$v3$clatTaxPackag === void 0 ? void 0 : _vm$$v3$clatTaxPackag.bankAccount
          },
          model: {
            value: _vm.clatTaxPackageModal.bankAccount,
            callback: function callback($$v) {
              _vm.$set(_vm.clatTaxPackageModal, "bankAccount", $$v);
            },
            expression: "clatTaxPackageModal.bankAccount"
          }
        }), _c('div', {
          staticClass: "sygni-modal-form__container"
        }, [_c('label', {
          staticClass: "debtor-data mb-3"
        }, [_c('span', {
          staticClass: "debtor-data__title"
        }, [_vm._v(" " + _vm._s(_vm.$t('marketplace.debtor_account_name')) + ": ")]), _vm._v(" " + _vm._s(((_vm$selectedBankAccou = _vm.selectedBankAccount) === null || _vm$selectedBankAccou === void 0 ? void 0 : _vm$selectedBankAccou.name) || '') + " ")]), _c('label', {
          staticClass: "debtor-data mb-3"
        }, [_c('span', {
          staticClass: "debtor-data__title"
        }, [_vm._v(" " + _vm._s(_vm.$t('marketplace.debtor_account_currency')) + ": ")]), _vm._v(" " + _vm._s(((_vm$selectedBankAccou2 = _vm.selectedBankAccount) === null || _vm$selectedBankAccou2 === void 0 ? void 0 : _vm$selectedBankAccou2.currency) || '') + " ")])])], 1)])])];
      },
      proxy: true
    }], null, false, 3283662851)
  }) : _vm._e(), _c('sygni-modal', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.detailsModalId,
      expression: "detailsModalId"
    }],
    attrs: {
      "header": "Payment details",
      "size": "large",
      "isLoading": _vm.isModalLoading,
      "confirmText": _vm.isPaymentManager ? 'Save changes' : '',
      "cancelText": "Close"
    },
    on: {
      "cancel": _vm.closeDetailsModal,
      "close": _vm.closeDetailsModal,
      "confirm": _vm.updatePaymentData
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        var _vm$openedRow, _vm$openedRow2, _vm$openedRow3;

        return [_c('div', {
          staticClass: "form"
        }, [_c('div', {
          staticClass: "form__row"
        }, [_c('div', {
          staticClass: "form__col"
        }, [_c('sygni-select', {
          staticClass: "secondary",
          attrs: {
            "label": "PAYMENT STATUS",
            "options": _vm.paymentStatuses,
            "value": (_vm$openedRow = _vm.openedRow) === null || _vm$openedRow === void 0 ? void 0 : _vm$openedRow.paymentStatus,
            "allow-empty": false,
            "disabled": !_vm.isPaymentManager
          },
          on: {
            "input": _vm.setPaymentStatus
          }
        })], 1), _c('div', {
          staticClass: "form__col"
        }, [_c('sygni-date-picker', {
          attrs: {
            "label": "PAYMENT DATE",
            "value": (_vm$openedRow2 = _vm.openedRow) === null || _vm$openedRow2 === void 0 ? void 0 : _vm$openedRow2.paymentDate,
            "disabled": !_vm.isPaymentManager
          },
          on: {
            "change": _vm.setPaymentDate
          }
        })], 1)]), _c('div', {
          staticClass: "form__row"
        }, [_c('div', {
          staticClass: "form__col"
        }, [_c('sygni-text-area', {
          attrs: {
            "label": "DESCRIPTION",
            "value": (_vm$openedRow3 = _vm.openedRow) === null || _vm$openedRow3 === void 0 ? void 0 : _vm$openedRow3.description,
            "disabled": !_vm.isPaymentManager
          },
          on: {
            "input": _vm.setDescription
          }
        })], 1)])])];
      },
      proxy: true
    }])
  }), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isBusy,
      expression: "isBusy"
    }],
    staticClass: "text-center text-danger spinner"
  }, [_c('b-spinner', {
    staticClass: "align-middle mr-3"
  }), _c('strong', [_vm._v("Loading...")])], 1)], 1), _c('clat-tax-modal', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.clatTaxModalOpened,
      expression: "clatTaxModalOpened"
    }],
    attrs: {
      "productIds": _vm.productIds,
      "preview": _vm.clatTaxPreview,
      "show": _vm.clatTaxModalOpened
    },
    on: {
      "close": _vm.closeClatTaxModal
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }