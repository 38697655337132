






























































































































































































































































import Component from 'vue-class-component'
import ExpandCollapse from "@/components/animations/ExpandCollapse.vue";
import SygniTable from "@/components/table/SygniTable.vue";
import SygniModal from "@/components/layout/SygniModal.vue";
import SygniSelect from "@/components/inputs/SygniSelect.vue";
import SygniCheckbox from "@/components/inputs/SygniCheckbox.vue";
import SygniTextArea from "@/components/inputs/SygniTextArea.vue";
import UserPresentation from "@/components/UserPresentation.vue";
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import SygniSquareButton from "@/components/buttons/SygniSquareButton.vue";
import SygniRectButton from "@/components/buttons/SygniRectButton.vue";
import SygniArrowButton from "@/components/buttons/SygniArrowButton.vue";
import { BSpinner, BTable, BvTableField } from "bootstrap-vue";
import SygniCircleButton from "@/components/buttons/SygniCircleStatusButton.vue";
import InvestorsInnerTable from "@/modules/genprox/modules/fund/modules/capital-rise/components/InvestorsInnerTable.vue";
import { CheckboxOption } from '@/store/types';
import { BACKEND_BASE_URL } from "@/shared/consts";
import Utils from "@/modules/shared/utils/utils";
import { Watch } from 'vue-property-decorator';
import { requiredIf } from 'vuelidate/lib/validators';
import { TableQuery } from '@/modules/shared/utils/TableQuery';
import SygniPagination from '@/components/table/SygniPagination.vue';
import SygniFlagPicker from '@/components/inputs/SygniFlagPicker.vue';
import SygniRadio from '@/components/inputs/SygniRadio.vue';
import SygniInput from '@/components/inputs/SygniInput.vue';
import SygniDatePicker from '@/components/inputs/SygniDatePicker.vue';
import SygniLoader from '@/components/layout/SygniLoader.vue';
import Vue from 'vue';
import { TemplateActions, TemplateStatuses } from '@/modules/genprox/modules/fund/modules/capital-rise/modules/templates/store/types';
import { Statues } from '@/shared/interfaces/Statues';
import { create, all } from 'mathjs'
import _ from 'lodash';

const math = create(all);

Component.registerHooks(['validations'])
@Component({
  components: {
    SygniCircleButton,
    InvestorsInnerTable, SygniLoader, SygniArrowButton, SygniRoundedButton, UserPresentation, ExpandCollapse, BSpinner, SygniSelect, SygniCheckbox, SygniTextArea, SygniSquareButton, SygniRectButton, SygniModal, SygniFlagPicker, SygniRadio, SygniInput, SygniDatePicker
  },
})
export default class PaymentsTable extends SygniTable<any> {
  TemplateStatuses: any = TemplateStatuses;
  TemplateActions: any = TemplateActions;
  isPaymentStatusLoading: boolean = false;
  isSepa: boolean = false;
  isAccountingDateLoading: boolean = false;
  isModalLoading: boolean = false;
  bulkRows: Array<CheckboxOption> = [];
  selectAllRowsBoolean: boolean | null = false;
  showBankAccountModal: boolean = false;
  bulkOptionsMarginTop: number = 0;
  isPaymentPackageLoading: boolean = false;
  useCustomPaymentDate: boolean = false;
  customPaymentDate: string = '';
  customPackageName: string = '';
  paymentForSingleDocument: any = false;
  bulkPaymentStatus: string = '';
  bulkPaymentStatusPlaceholder: string = 'Select option';
  accountingDateOpened: boolean = false;
  showBulkDetailsModal: boolean = false;
  isBulkModalLoading: boolean = false;
  isCsvExporting: boolean = false;
  accountingDate: string = '';
  detailsModalId: string = '';
  bulkDetailsData: { toUpdate: { paymentStatus: boolean, paymentBookDate: boolean, description: boolean }, paymentStatus: string, paymentBookDate: string, description: string } = {
    toUpdate: {
      paymentStatus: false,
      paymentBookDate: false,
      description: false,
    },
    paymentStatus: null,
    paymentBookDate: null,
    description: null,
  }
  
  refresh: any = null;

  tableFields: (BvTableField & { borderless?: Boolean } & { key: string })[] = [
    { key: 'productCode', sortable: true, borderless: true, class: 'text-left', label: 'Product Code' },
    { key: 'investmentClient', class: 'text-center', sortable: true, borderless: true, label: 'Investor' },
    { key: 'productType', sortable: true, borderless: true, class: 'text-center', label: 'Product Type' },
    { key: 'paymentBookDate', class: 'text-center', sortable: true, borderless: true, label: 'Payment Book Date' },
    { key: 'repaymentDate', class: 'text-center', sortable: true, borderless: true, label: 'Outflow Date' },
    { key: 'interestPeriodFrom', class: 'text-center', sortable: true, borderless: true, label: 'Date From' },
    { key: 'interestPeriodTo', class: 'text-center', sortable: true, borderless: true, label: 'Date To' },
    { key: 'paymentType', class: 'text-center', sortable: true, borderless: false, label: 'Payment Type' },
    { key: 'interestTax', class: 'text-right', sortable: true, borderless: false, label: 'Tax Value' },
    { key: 'interestAmountNet', class: 'text-right', sortable: true, borderless: false, label: 'Net Value' },
    { key: 'interestAmountGross', class: 'text-right', sortable: true, borderless: false, label: 'Gross Value' },
    { key: 'paymentStatus', class: 'text-center', sortable: true, borderless: false, label: 'Payment Status' },
    // { key: 'bankAccount', class: 'text-center', sortable: true, borderless: false, label: 'Bank Account' },
    { key: 'actions', sortable: false, label: 'Actions' },
  ];

  get showBulkOptions() {
    return !!(this.isPaymentManager || this.isMarketplaceAdmin || this.isMarketplaceHead || this.isMarketplaceAccountant)
  }

  get tableFieldsComputed() {
    const fields: any = this.tableFields

    if (this.showBulkOptions) {
      fields.unshift({ key: 'selected', sortable: false, borderless: true, label: '' })
    }

    return fields
  }

  enableBankAccountModal(item?: any) {
    this.paymentForSingleDocument = (item?.id) ? item.id : false;
    this.showBankAccountModal = true;
  }

  closeBankAccountModal() {
    this.showBankAccountModal = false;
    this.$v.$reset();
  }
  
  get isMarketplaceAdmin() {
    return this.activeUserData?.role?.marketplace === 'admin' ? true : false;
  }

  get isMarketplaceHead() {
    return this.activeUserData?.role?.marketplace === 'head' ? true : false;
  }

  get isMarketplaceAccountant() {
    return this.activeUserData?.role?.marketplace === 'accountant' ? true : false;
  }

  getTooltipStatusMessage(item: any) {
    if (item.status == 'rejected') return item?.note ? item.note : '';

    return item.status == 'failed' ? item.errorMessage : '';
  }

  closeRejectionModal() {
    this.showRejectionModal = false;
    this.rejectionNote = '';
  }

  enableRejectionModal() {
    this.showRejectionModal = true;
  }

  getRowDataActionItems(items: Array<string>) {
    const totalItems = [...items, 'download'];

    return totalItems;
  }

  get netTotals() {
    let totals: any = {};

    this.items.forEach((item: any) => {
      if (item.currency) {
        if (totals[item.currency]) {
          totals[item.currency] = item.interestAmountNet ? math.number(math.add(math.bignumber(totals[item.currency]), math.bignumber(item.interestAmountNet))) : math.number(math.add(math.bignumber(totals[item.currency]), 0));
        } else {
          totals[item.currency] = item.interestAmountNet ? math.bignumber(math.bignumber(item.interestAmountNet)) : 0;
        }
      }
    })

    return totals;
  }

  get grossTotals() {
    let totals: any = {};

    this.items.forEach((item: any) => {
      if (item.currency) {
        if (totals[item.currency]) {
          totals[item.currency] = item.interestAmountGross ? math.number(math.add(math.bignumber(totals[item.currency]), math.bignumber(item.interestAmountGross))) : math.number(math.add(math.bignumber(totals[item.currency]), 0));
        } else {
          totals[item.currency] = item.interestAmountGross ? math.bignumber(math.bignumber(item.interestAmountGross)) : 0;
        }
      }
    })

    return totals;
  }

  get taxTotals() {
    let totals: any = {};

    this.items.forEach((item: any) => {
      if (item.currency) {
        if (totals[item.currency]) {
          totals[item.currency] = item.interestTax ? math.number(math.add(math.bignumber(totals[item.currency]), math.bignumber(item.interestTax))) : math.number(math.add(math.bignumber(totals[item.currency]), 0));
        } else {
          totals[item.currency] = item.interestTax ? math.bignumber(math.bignumber(item.interestTax)) : 0;
        }
      }
    })

    return totals;
  }

  statusClass(status: string) {
    switch (status) {
      case ('unpaid'): return 'danger';
      case ('paid'): return 'primary';
      case ('scheduled'): return 'success';
      case ('cancelled'): return 'danger';
      case ('partially paid'): return 'lead';
      case ('annex'): return 'lead';
    }

    return 'black';
  }

  setPaymentStatus(value: any) {
    this.openedRow.paymentStatus = value;
  }

  setBulkPaymentStatus(value: any) {
    this.bulkDetailsData.paymentStatus = value;
  }

  setPaymentBookDate(value: any) {
    this.openedRow.paymentBookDate = value ? value : null;
  }

  setBulkPaymentBookDate(value: any) {
    this.bulkDetailsData.paymentBookDate = value ? value : null;
  }

  setDescription(value: any) {
    this.openedRow.description = value ? value : null;
  }

  setBulkDescription(value: any) {
    this.bulkDetailsData.description = value ? value : null;
  }

  get paymentStatuses() {
    return this.paymentDictionaries?.paymentStatus ? this.paymentDictionaries?.paymentStatus?.map((el: any) => {
      return { label: this.$options.filters.capitalizeFirstLetter(el.label), value: el.value }
    }) : []
  }

  get paymentDictionaries() {
    return this.$store.getters['products/getDictionary'] ? this.$store.getters['products/getDictionary'] : {};
  }

  get parentLink() {
    return (this.$route.path.includes('company')) ? 'company' : 'fund';
  }

  get actionItems() {
    if (this.isMarketplaceAccountant) {
      return ['preview']
    }

    let actions: Array<string> = [];

    this.items.forEach((item: any) => {
      actions.push(...item.actions);
    });

    actions = actions.filter((action: string) => {
      return !(action == 'edit-payment-status' || action == 'preview' || action == 'approve' || action == 'reject');
    });

    actions = [...new Set(actions), 'download'];

    if (actions.indexOf('delete') != -1) {
      actions.push(actions.splice(actions.indexOf('delete'), 1)[0]);
    }

    return actions;
  }

  get formattedActionItems() {
    return this.actionItems.map((value: string, index: number) => {
      return {
        id: index + 1,
        key: value,
      }
    })
  }

  get actionItemsLength() {
    return this.actionItems.length;
  }

  get defaultPaymentPackageName() {
    const date = new Date();
    return `Payment package from ${date.getFullYear()}-${Utils.pad(date.getMonth() + 1, 2)}-${Utils.pad(date.getDate() + 1, 2)} ${Utils.pad(date.getHours(), 2)}:${Utils.pad(date.getMinutes(), 2)}:${Utils.pad(date.getSeconds(), 2)}`;
  }

  async exportCsv() {
    this.isCsvExporting = true;

    try {
      let csvContent = await this.$store.dispatch('products/exportCsvByIds', { ids: this.rowsSelected.map((el: any) => el.label) });
      csvContent = csvContent.replace(/[\r]+/g, '').replace(';', ' ').replace(/&nbsp;/g, '').replace(/"[^"]*(?:""[^"]*)*"/g, function (m: any) { return m.replace(/\n/g, ''); })
      const universalBOM: string = '\uFEFF';
      let data = `data:text/csv;charset=utf-8,${encodeURIComponent(universalBOM + csvContent)}`;
      const link = document.createElement('a');
      link.setAttribute('href', data)
      link.setAttribute('download', `export.csv`);
      document.body.appendChild(link);
      link.click();
      link.remove();

    } catch(error) {
      const errorMessage = this.$options.filters.errorHandler(error);
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Error',
        text: this.$t(errorMessage).toString()
      });
    }

    this.isCsvExporting = false;
  }

  onFiltersChange(filtersQuery?: string): void {
    this.$store.commit(this.setTableBusyMutation, true);
    this.$store.commit('products/setScheduleTableFiltersQuery', filtersQuery);
    this.paginationInstance.$emit('changePage', 1);
    this.$nextTick(() => {
      const sign: string = this.sortDesc ? '-' : '';
      let sortBy: string = '';

      switch (this.sortBy) {
        default:
          sortBy = this.sortBy;
          break;
      }

      this.sortingQuery = {
        name: sortBy,
        order: sign,
      }

      this.$store.commit('products/setScheduleTableSortingQuery', this.sortingQuery);
      this.getItems();
    });
  }

  onSortChange(): void {
    this.$store.commit(this.setTableBusyMutation, true);
    this.paginationInstance.$emit('changePage', 1);
    this.$nextTick(() => {
      const sign: string = this.sortDesc ? '-' : '';
      let sortBy: string = '';

      switch (this.sortBy) {
        default:
          sortBy = this.sortBy;
          break;
      }

      this.sortingQuery = {
        name: sortBy,
        order: sign,
      }

      this.$store.commit(this.$attrs.sortingQueryAction, this.sortingQuery);
      this.getItems();
    });
  }

  openDetailsModal(id: string) {
    this.detailsModalId = id;
  }

  closeDetailsModal() {
    this.detailsModalId = null;
    this.refreshTable();
  }

  openBulkDetailsModal() {
    this.showBulkDetailsModal = true;
  }

  closeBulkDetailsModal() {
    this.showBulkDetailsModal = false;
    this.$set(this.bulkDetailsData.toUpdate, 'paymentStatus', false)
    this.$set(this.bulkDetailsData.toUpdate, 'paymentBookDate', false)
    this.$set(this.bulkDetailsData.toUpdate, 'description', false)
    this.$set(this.bulkDetailsData, 'paymentStatus', null)
    this.$set(this.bulkDetailsData, 'paymentBookDate', null)
    this.$set(this.bulkDetailsData, 'description', null)
    this.refreshTable();
  }

  get openedRow() {
    return this.items?.find((el: any) => el.id === this.detailsModalId) ? this.items?.find((el: any) => el.id === this.detailsModalId) : null;
  }

  openAccountingDateModal() {
    this.accountingDateOpened = true;
  }

  closeAccountingDateModal() {
    this.accountingDateOpened = false;
  }

  refreshTable(): void {
    this.$store.commit(this.setTableBusyMutation, true);
    this.$nextTick(() => {
      this.getItems();
    })
  }

  toggleAllRowsAction() {
    if (this.selectAllRowsBoolean === null) return;
    this.selectAllRowsBoolean = !this.selectAllRowsBoolean;

    if (this.selectAllRowsBoolean) {
      const selectedRowEl = (this.$refs.paymentsTable as BTable).$el.querySelector('.table tbody tr:nth-of-type(1)');
      this.bulkOptionsMarginTop = (selectedRowEl as HTMLDivElement)?.offsetTop + (selectedRowEl as HTMLDivElement)?.offsetHeight - 20;
      (this.$refs.paymentsTable as BTable).selectAllRows();
    } else {
      (this.$refs.paymentsTable as BTable).clearSelected();
    }

    this.bulkRows.forEach(row => {
      row.value = this.selectAllRowsBoolean;
    });
  }

  selectRowEl(index: number) {
    if (this.$refs.paymentsTable == undefined) return;

    this.deselectRowEl();
    const activeRow = ((this.$refs.paymentsTable as PaymentsTable).$el as HTMLElement).querySelector(`tbody tr[aria-rowindex="${index + 1}"]`);
    this.$scrollTo(activeRow);
    activeRow.classList.add('active');
  }

  deselectRowEl() {
    if (this.$refs.paymentsTable == undefined) return;

    const rows = ((this.$refs.paymentsTable as PaymentsTable).$el as HTMLElement).querySelectorAll(`tbody tr`);
    rows.forEach(row => row.classList.remove('active'));
  }

  get paymentsTableQuery() {
    return this.$store.getters['products/getScheduleTableQuery'];
  }

  toggleTableRow(item: any, index: number) {
    const selectedRowEl = (this.$refs.paymentsTable as BTable).$el.querySelector(`.table tbody tr:nth-of-type(${index + 1})`);
    this.bulkOptionsMarginTop = (selectedRowEl as HTMLDivElement)?.offsetTop + (selectedRowEl as HTMLDivElement)?.offsetHeight - 20;
    if (this.bulkRows[index]) {
      this.bulkRows[index].value = !this.bulkRows[index].value;
    }

    if (item.value) {
      (this.$refs.paymentsTable as BTable).selectRow(index);
    } else {
      (this.$refs.paymentsTable as BTable).unselectRow(index);
    }
  }

  clearTableSelection() {
    this.selectAllRowsBoolean = false;
    this.bulkRows.filter(el => el.value !== undefined).forEach(row => {
      row.value = false;
    });
  }

  downloadDocument() {
    if (this.activeDocument == undefined) return;
    this.downloadFileByUrl(this.activeDocument);
  }

  setQuery() {
    this.$store.commit('products/setScheduleTableQuery', this.localTableQuery);
  }

  async getItems() {
    await this.$store.dispatch(this.$attrs.getItemsAction, '');
    this.resetCheckboxes();
  }

  resetCheckboxes(): void {
    this.bulkRows = [];
    this.items.forEach((el: any, index: any) => {
      this.$set(this.bulkRows, index, { label: el.id, value: false });
    });
  }

  downloadFileByUrl(file: any) {
    Utils.downloadFileByUrl(`${BACKEND_BASE_URL}${file.pdfFilePath}`, file.fileName);
  }

  downloadMultipleDocuments() {
    const documentUrls = this.rowsSelected.map(el => {
      const item = this.items.find((item: any) => el.label == item.id);
      return { fileName: item.fileName, path: item.pdfFilePath };
    });
    Utils.downloadZippedFilesByUrls(documentUrls);
  }

  deleteRow(item: any) {
    this.$emit('deleteRow', item);
  }

  get filtersQuery() {
    return this.$store.getters['products/getScheduleTableFiltersQuery'];
  }

  get rowsSelected() {
    const selectedRows = this.bulkRows.filter(el => el.value);

    return selectedRows;
  }

  get paymentPackageItems() {
    let items: any = this.rowsSelected.map((el: any) => {
      return this.items.find((item: any) => item.id === el.label) ? this.items.find((item: any) => item.id === el.label) : el;
    });

    items = _.groupBy(items, 'productId')
    const results: any = [];

    Object.keys(items).forEach((item: any) => {
      results.push({
        productId: item,
        paymentIds: items[item].map((el: any) => el.id)
      })
    })

    return results;
  }

  get selectedRowsLength() {
    const selectedRows = this.bulkRows.filter(el => el.value);

    return selectedRows?.length;
  }

  get activeUserData() {
    return this.$store.getters['genprox/activeUserData'];
  }

  get isPaymentManager() {
    return this.activeUserData.role?.paymentManager ? true : false;
  }

  validations () {
    return  {
      customPaymentDate: { required: requiredIf(() => this.useCustomPaymentDate) }
    }
  }

  async generatePaymentPackage() {
    let items = this.paymentPackageItems;

    if (this.paymentForSingleDocument) {
      const item = this.items.find((el: any) => el.id === this.paymentForSingleDocument)
      items = [{
        productId: item.productId,
        paymentIds: [item.id]
      }]
    }

    this.$v.$touch();

    if (!this.$v.$error) {
      this.isPaymentPackageLoading = true;
      const packageName = this.customPackageName ? this.customPackageName : this.defaultPaymentPackageName;
      const paymentDate = this.useCustomPaymentDate ? `${this.customPaymentDate} 00:00:00` : null;

      if (items.length) {
        try {
          await this.$store.dispatch('products/generatePaymentPackage', { items: items, paymentDate: paymentDate, name: packageName, isSepa: this.isSepa });
          this.closeBankAccountModal();
          this.onFiltersChange(this.filtersQuery);
        } catch (error) {
          const errorMessage = this.$options.filters.errorHandler(error);
          this.$notify({
            duration: 2500,
            type: 'error',
            title: 'Error',
            text: this.$t(errorMessage).toString()
          });
        }
      }

      this.isPaymentPackageLoading = false;
    }
  }

  async beforeMount() {
    await this.getItems();
    this.onMounted();
    this.setBorderlessTds();
  }

  async goToProductSummary(item: any) {
    this.$store.commit('investors/clearAnnexingData');
    if (item.productStatus === Statues.pending) {
      await this.$router.push({ path: `/${this.$route.path.includes('company') ? 'company' : 'fund'}/capital-rise/product/accept-by-legal-entity/${item.productId}` });
    } else {
      await this.$router.push({ path: `/${this.$route.path.includes('company') ? 'company' : 'fund'}/capital-rise/product/summary/${item.productId}/for-legal-entity` });
    }
  }

  goToProfile(item: any): void {
    if(item.investmentClientId) {
      this.$router.push({name: 'profile-dashboard-guest-investor', params: { id: item.investmentClientId}})
    }
  }

  addPagination(): void {
    const ComponentClass = Vue.extend(SygniPagination);
    this.paginationInstance = new ComponentClass<SygniPagination>();

    const currentPage = this.paymentsTableQuery.offset / this.paymentsTableQuery.limit + 1;

    this.paginationInstance.showPageNumberOptions = this.showPageNumberOptions;
    this.paginationInstance.perPage = this.tableData.perPage;
    this.paginationInstance.totalRows = this.tableData.totalCount;
    this.paginationInstance.currentPage = currentPage;
    this.paginationInstance.$mount();
    this.paginationInstance.$on('change', (currentPage: number) => {
      this.$store.commit(this.setTableBusyMutation, true);
      this.localTableQuery = new TableQuery().setQuery(this.tableData.query);
      this.localTableQuery.limit = this.tableData.perPage;
      this.localTableQuery.offset = this.tableData.perPage * (currentPage - 1);
      this.setQuery();
      this.getItems();
      this.$scrollTo(((this.$refs.paymentsTable as PaymentsTable).$el as HTMLElement));
    });
    this.paginationInstance.$on('changePerPageOption', (perPage: number) => {
      if (this.tablePerPageMutation) this.$store.commit(this.tablePerPageMutation, perPage);
      this.$store.commit(this.setTableBusyMutation, true);
      this.localTableQuery = new TableQuery().setQuery(this.tableData.query);
      this.localTableQuery.limit = this.tableData.perPage;
      this.localTableQuery.offset = 0;
      this.setQuery();
      this.getItems();
      this.changePerPageOption();
    });

    this.$nextTick(() => {
      this.paginationInstance.$emit('changePage', currentPage)
    })

    const tableElement: Element = this.$el.querySelector('.sygni-b-table') as Element;

    const isPaymentsTable = this.$el.classList.contains('payments-table');
    const isWhistleblowerTable = this.$el.classList.contains('whistleblower-table');
    const isPortfolioTable = this.$el.classList.contains('portfolio-table');

    if (isPaymentsTable) {
      this.$el.querySelector('.payments-table__inner').insertBefore(this.paginationInstance.$el as Node, tableElement.nextSibling);
    } else if (isWhistleblowerTable) {
      this.$el.querySelector('.whistleblower-table__inner').insertBefore(this.paginationInstance.$el as Node, tableElement.nextSibling);
    } else if (isPortfolioTable) {
      this.$el.querySelector('.portfolio-table__inner').insertBefore(this.paginationInstance.$el as Node, tableElement.nextSibling);
    } else {
      this.$el.insertBefore(this.paginationInstance.$el as Node, tableElement.nextSibling);
    }
  }

  async paymentStatusBulkUpdate() {
    const selectedRowsIds = this.bulkRows.filter(el => el.value).map(el => el.label);

    const items = selectedRowsIds.map((id: string) => {
      const item = this.items.find((el: any) => el.id === id);
      return {
        paymentId: item.id,
        productId: item.productId,
      }
    });

    const payload = { items: items, status: this.bulkPaymentStatus };
    this.isPaymentStatusLoading = true;

    try {
      await this.$store.dispatch('products/updatePaymentStatuses', payload);
      selectedRowsIds.forEach((id: any) => {
        const item = this.items.find(el => el.id == id);
        item.paymentStatus = payload.status;
      });
      this.isPaymentStatusLoading = false;
      this.$notify({
        duration: 2500,
        type: 'success',
        title: 'Success',
        text: 'Payment statuses updated.'
      });
    } catch (err) {
      const errorMessage = this.$options.filters.errorHandler(err);
      this.isPaymentStatusLoading = false;
      this.$notify({
        duration: 3000,
        type: 'error',
        title: 'Error',
        text: errorMessage
      })
    }
  }

  async accountingDateBulkUpdate() {
    const selectedRowsIds = this.bulkRows.filter(el => el.value).map(el => el.label);

    const items = selectedRowsIds.map((id: string) => {
      const item = this.items.find((el: any) => el.id === id);
      return {
        paymentId: item.id,
        productId: item.productId,
      }
    });

    const payload = { items: items, paymentBookDate: this.accountingDate };
    this.isAccountingDateLoading = true;

    try {
      await this.$store.dispatch('products/updatePaymentBookDates', payload);
      selectedRowsIds.forEach((id: any) => {
        const item = this.items.find(el => el.id == id);
        item.paymentBookDate = payload.paymentBookDate;
      });
      this.$notify({
        duration: 2500,
        type: 'success',
        title: 'Success',
        text: 'Payment book dates updated.'
      });
    } catch (err) {
      const errorMessage = this.$options.filters.errorHandler(err);
      this.$notify({
        duration: 3000,
        type: 'error',
        title: 'Error',
        text: errorMessage
      })
    }

    this.isAccountingDateLoading = false;
  }

  async updateBulkPaymentData() {
    this.isBulkModalLoading = true;

    const selectedRowsIds = this.bulkRows.filter(el => el.value).map(el => el.label);
    const items = selectedRowsIds.map((id: string) => {
      const item = this.items.find((el: any) => el.id === id);
      return {
        paymentId: item.id,
        productId: item.productId,
      }
    });

    try {
      const promises: any[] = []
      if (this.bulkDetailsData?.toUpdate?.paymentStatus) {
        const payload = { items: items, status: this.bulkDetailsData?.paymentStatus };
        promises.push(this.$store.dispatch('products/updatePaymentStatuses', payload));
      }

      if (this.bulkDetailsData?.toUpdate?.paymentBookDate) {
        const payload = { items: items, paymentBookDate: this.bulkDetailsData?.paymentBookDate };
        promises.push(this.$store.dispatch('products/updatePaymentBookDates', payload));
      }

      if (this.bulkDetailsData?.toUpdate?.description) {
        const payload = { items: items, description: this.bulkDetailsData?.description };
        promises.push(this.$store.dispatch('products/updateDescriptions', payload))
      }

      await Promise.all(promises)
      this.closeBulkDetailsModal()
      this.$notify({
        duration: 3000,
        type: 'success',
        title: 'Success',
        text: 'Details updated.'
      })

    } catch (err) {
      const errorMessage = this.$options.filters.errorHandler(err);
      this.$notify({
        duration: 3000,
        type: 'error',
        title: 'Error',
        text: errorMessage
      })
    }

    this.isBulkModalLoading = false
  }

  async updatePaymentData() {
    this.isModalLoading = true;
    try {
      const productId = this.openedRow.productId;
      const paymentId = this.openedRow.id;
      const data = {
        paymentStatus: this.openedRow.paymentStatus,
        paymentMethod: 'bank transfer',
        paymentBookDate: this.openedRow.paymentBookDate,
        description: this.openedRow.description,
      };

      const payload = { productId, paymentId, data };

      await this.$store.dispatch('products/updatePaymentData', payload)

      this.$notify({
        duration: 2500,
        type: 'success',
        title: 'Success',
        text: 'Payment details updated.'
      });
      this.closeDetailsModal();

    } catch (err) {
      const errorMessage = this.$options.filters.errorHandler(err);
      this.$notify({
        duration: 3000,
        type: 'error',
        title: 'Error',
        text: errorMessage
      })
    }
    this.isModalLoading = false;
  }

  @Watch('bulkPaymentStatus') onBulkPaymentStatusChange(): void {
    if (this.bulkPaymentStatus) {
      this.paymentStatusBulkUpdate();
    }
  }

  @Watch('accountingDate') onAccountingDateChange() {
    if (this.accountingDate) {
      this.accountingDateBulkUpdate();
    }
  }

  @Watch('selectAllRowsBoolean') onSelectAllRowsBooleanChange(): void {
    const selectedRowEl = (this.$refs.paymentsTable as BTable).$el.querySelector(`.table tbody tr:nth-of-type(1)`);
    this.bulkOptionsMarginTop = (selectedRowEl as HTMLDivElement)?.offsetTop + (selectedRowEl as HTMLDivElement)?.offsetHeight - 20;

    this.bulkRows.filter(el => el.value !== undefined).forEach(row => {
      row.value = this.selectAllRowsBoolean;
    });
  }

  @Watch('items') onItemsChange(): void {
    if (this.refresh) {
      clearTimeout(this.refresh);
      this.refresh = undefined;
    }

    if (this.isProcessing) {
      this.$store.commit('products/setScheduleTableBusy', true);
      this.refresh = setTimeout(() => {
        this.onFiltersChange(this.filtersQuery);
      }, 3000);
    }
  }
}

