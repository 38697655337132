var render = function () {
  var _vm$bulkDetailsData, _vm$bulkDetailsData2, _vm$bulkDetailsData2$;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "marketplace-module"
  }, [_c('div', {
    staticClass: "table-container"
  }, [_c('div', {
    staticClass: "table"
  }, [_c('sygni-tabs', {
    ref: "tabs",
    attrs: {
      "options": _vm.pageOptions
    },
    on: {
      "click": _vm.selectTab
    },
    scopedSlots: _vm._u([{
      key: "beforeContent",
      fn: function fn() {
        return [_c('marketplace-filters', {
          attrs: {
            "type": _vm.selectedTab
          },
          on: {
            "filtersChange": _vm.applyFiltersEvent
          }
        })];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function fn() {
        return [_vm.selectedTab === 'advisors' && _vm.isMarketplaceAdmin ? _c('div', {
          staticClass: "router-link",
          on: {
            "click": _vm.goToAdvisorForm
          }
        }, [_c('sygni-rounded-button', {
          staticClass: "filled red",
          attrs: {
            "selectable": false,
            "plus-icon": true
          }
        }, [_vm._v(" Add new advisor ")])], 1) : _vm._e(), _vm.selectedTab === 'advisors' && _vm.isMarketplaceAdmin ? _c('div', {
          staticClass: "router-link",
          on: {
            "click": function click($event) {
              return _vm.$router.push("".concat(_vm.$route.path, "/advisor-form-settings"));
            }
          }
        }, [_vm.hasAdvisorForm ? _c('sygni-rounded-button', {
          staticClass: "outline black square"
        }, [_c('img', {
          attrs: {
            "src": _vm._f("getIcon")('SETTINGS'),
            "alt": ""
          }
        })]) : _vm._e(), !_vm.hasAdvisorForm ? _c('sygni-rounded-button', {
          staticClass: "outline black"
        }, [_vm._v("Configure advisor form "), _c('img', {
          staticClass: "ml-3",
          attrs: {
            "src": _vm._f("getIcon")('SETTINGS'),
            "alt": ""
          }
        })]) : _vm._e()], 1) : _vm._e(), _vm.investorsTableItems && _vm.investorsTableItems.length > 0 && _vm.selectedTab === 'investors' && _vm.hasLeadForm && _vm.manageInvestorsEnabled ? _c('div', {
          staticClass: "router-link",
          on: {
            "click": function click($event) {
              return _vm.$router.push("".concat(_vm.$route.path, "/new-investor/form/step-one"));
            }
          }
        }, [_c('sygni-rounded-button', {
          staticClass: "filled red",
          attrs: {
            "selectable": false,
            "plus-icon": true
          }
        }, [_vm._v(" Add new investor ")])], 1) : _vm._e(), _vm.selectedTab === 'products' && !_vm.isMarketplaceAccountant ? _c('div', {
          staticClass: "router-link"
        }, [_c('sygni-rounded-button', {
          staticClass: "filled red",
          attrs: {
            "plusIcon": true
          },
          on: {
            "click": function click($event) {
              return _vm.$router.push("".concat(_vm.$route.path, "/product/new/step-one"));
            }
          }
        }, [_vm._v("Add new product")])], 1) : _vm._e(), (_vm.selectedTab === 'investors' || _vm.selectedTab === 'products') && (_vm.isMarketplaceAdmin || _vm.isMarketplaceHead) && _vm.hasAutenti ? _c('div', {
          staticClass: "router-link"
        }, [_c('sygni-rounded-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            value: _vm.areAutentiStatusRefreshed ? 'Autenti statuses are refreshing' : 'Refresh Autenti statuses',
            expression: "areAutentiStatusRefreshed ? 'Autenti statuses are refreshing' : 'Refresh Autenti statuses'",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "outline black square refresh",
          attrs: {
            "loading": _vm.areAutentiStatusRefreshed
          },
          on: {
            "click": _vm.refreshAutentiStatuses
          }
        }, [_c('img', {
          attrs: {
            "src": _vm._f("getIcon")('REFRESH'),
            "alt": ""
          }
        })])], 1) : _vm._e(), _vm.selectedTab === 'investors' && _vm.isMarketplaceAdmin ? _c('div', {
          staticClass: "router-link",
          on: {
            "click": function click($event) {
              return _vm.$router.push("".concat(_vm.$route.path, "/lead-form-settings"));
            }
          }
        }, [_vm.hasLeadForm ? _c('sygni-rounded-button', {
          staticClass: "outline black square"
        }, [_c('img', {
          attrs: {
            "src": _vm._f("getIcon")('SETTINGS'),
            "alt": ""
          }
        })]) : _vm._e(), !_vm.hasLeadForm ? _c('sygni-rounded-button', {
          staticClass: "outline black"
        }, [_vm._v("Configure lead form "), _c('img', {
          staticClass: "ml-3",
          attrs: {
            "src": _vm._f("getIcon")('SETTINGS'),
            "alt": ""
          }
        })]) : _vm._e()], 1) : _vm._e(), _vm.selectedTab === 'subscriptions' && !_vm.isMarketplaceAccountant ? _c('div', {
          staticClass: "router-link"
        }, [_c('sygni-rounded-button', {
          staticClass: "filled red",
          attrs: {
            "plusIcon": true
          },
          on: {
            "click": function click($event) {
              return _vm.$router.push("".concat(_vm.$route.path, "/new-subscription"));
            }
          }
        }, [_vm._v("Add new subscription")])], 1) : _vm._e(), _vm.selectedTab === 'templates' && _vm.isMarketplaceAdmin ? _c('div', {
          staticClass: "router-link",
          on: {
            "click": function click($event) {
              return _vm.goToTemplateForm('template');
            }
          }
        }, [_c('sygni-rounded-button', {
          staticClass: "filled red",
          attrs: {
            "plusIcon": true
          }
        }, [_vm._v("Create template")])], 1) : _vm._e(), _vm.selectedTab === 'templates' && _vm.isMarketplaceAdmin ? _c('div', {
          staticClass: "router-link",
          on: {
            "click": function click($event) {
              return _vm.goToTemplateForm('attachment');
            }
          }
        }, [_c('sygni-rounded-button', {
          staticClass: "filled black",
          attrs: {
            "plusIcon": true
          }
        }, [_vm._v("Create attachment")])], 1) : _vm._e(), _vm.selectedTab === 'templates' && (_vm.isMarketplaceAdmin || _vm.isMarketplaceHead) && _vm.hasAutenti ? _c('div', {
          staticClass: "router-link",
          on: {
            "click": function click($event) {
              return _vm.$router.push("".concat(_vm.$route.path, "/autenti/settings"));
            }
          }
        }, [_c('sygni-rounded-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            value: 'Autenti configuration',
            expression: "'Autenti configuration'",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "outline black square"
        }, [_c('img', {
          attrs: {
            "src": _vm._f("getIcon")('AUTENTI'),
            "alt": ""
          }
        })])], 1) : _vm._e(), _vm.selectedTab === 'campaigns' && _vm.isMarketplaceAdmin ? _c('div', {
          staticClass: "router-link",
          on: {
            "click": _vm.goToCampaignForm
          }
        }, [_c('sygni-rounded-button', {
          staticClass: "filled red",
          attrs: {
            "plusIcon": true
          }
        }, [_vm._v("Add new campaign")])], 1) : _vm._e(), _vm.selectedTab === 'payments' && _vm.isPaymentManager ? _c('div', {
          staticClass: "router-link",
          on: {
            "click": _vm.openPaymentPackageModal
          }
        }, [_c('sygni-rounded-button', {
          staticClass: "filled red",
          attrs: {
            "loading": _vm.isPaymentPackageLoading
          }
        }, [_vm._v("Generate payment package")])], 1) : _vm._e(), _vm.selectedTab === 'payments' && _vm.isPaymentManager ? _c('div', {
          staticClass: "router-link"
        }, [_c('sygni-rounded-button', {
          staticClass: "filled black",
          on: {
            "click": _vm.openBulkDetailsModal
          }
        }, [_vm._v("Edit details")])], 1) : _vm._e(), _vm.selectedTab === 'payments' ? _c('div', {
          staticClass: "router-link",
          on: {
            "click": _vm.exportCsvByFilters
          }
        }, [_c('sygni-rounded-button', {
          staticClass: "outline black",
          attrs: {
            "loading": _vm.isCsvExporting
          }
        }, [_vm._v("Export to CSV")])], 1) : _vm._e(), _vm.selectedTab === 'clatTax' && _vm.isPaymentManager ? _c('div', {
          staticClass: "router-link",
          on: {
            "click": _vm.openClatTaxPaymentPackageModal
          }
        }, [_c('sygni-rounded-button', {
          staticClass: "filled red",
          attrs: {
            "loading": _vm.isClatTaxPaymentPackageLoading
          }
        }, [_vm._v("Generate payment package")])], 1) : _vm._e()];
      },
      proxy: true
    }, {
      key: "subscriptions",
      fn: function fn() {
        return [_c('subscriptions-table', {
          ref: "subscriptionsTable",
          attrs: {
            "tableData": _vm.subscriptionsTableItems,
            "busy": _vm.subscriptionsTableIsBusy,
            "sortingQueryAction": "subscriptions/setSubscriptionsTableSortingQuery",
            "getItemsAction": "subscriptions/getSubscriptions",
            "setTableBusyMutation": "subscriptions/setSubscriptionsTableBusy",
            "tablePerPageMutation": "subscriptions/setSubscriptionsTablePerPage",
            "perPage": _vm.subscriptionsPerPage,
            "showPageNumberOptions": true
          }
        })];
      },
      proxy: true
    }, {
      key: "investors",
      fn: function fn() {
        return [_vm.investorsTableItems && _vm.investorsTableItems.length > 0 ? _c('investors-table', {
          ref: "investorsTable",
          attrs: {
            "items": _vm.investorsTableItems,
            "sortingQueryAction": 'investors/setInvestorsQuery',
            "showPageNumberOptions": true,
            "getItemsAction": 'investors/getInvestors',
            "tablePerPageMutation": 'investors/setInvestorsTablePerPage',
            "setTableBusyMutation": 'investors/setInvestorsTableBusy',
            "perPage": _vm.investorsTablePerPage,
            "busy": _vm.investorsTableIsBusy,
            "getQuery": "investors/getInvestorsQuery"
          }
        }) : _c('empty-investors-table', {
          attrs: {
            "manageInvestorsEnabled": _vm.manageInvestorsEnabled
          }
        })];
      },
      proxy: true
    }, {
      key: "products",
      fn: function fn() {
        return [_c('product-table', {
          ref: "productTable",
          attrs: {
            "tableData": _vm.productsTableItems,
            "sortingQueryAction": 'products/setProductsTableQuery',
            "getItemsAction": 'products/getProducts',
            "tablePerPageMutation": "products/setProductsTablePerPage",
            "setTableBusyMutation": 'products/setProductsTableBusy',
            "busy": _vm.productsTableIsBusy,
            "showPagination": true,
            "showPageNumberOptions": true
          }
        })];
      },
      proxy: true
    }, _vm.isMarketplaceAdmin || _vm.isMarketplaceAccountant ? {
      key: "advisors",
      fn: function fn() {
        return [_c('advisors-table', {
          ref: "advisorsTable",
          attrs: {
            "tableData": _vm.advisorsTableItems,
            "busy": _vm.advisorsTableIsBusy,
            "sortingQueryAction": "advisors/setAdvisorsTableSortingQuery",
            "getItemsAction": "advisors/getBrokers",
            "setTableBusyMutation": "advisors/setAdvisorsTableBusy",
            "tablePerPageMutation": "advisors/setAdvisorsTablePerPage",
            "perPage": _vm.advisorsPerPage,
            "showPageNumberOptions": true
          }
        }), _c('flags-management-modal', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.showFlagManagementModal && !_vm.showFlagAccessModal,
            expression: "showFlagManagementModal && !showFlagAccessModal"
          }],
          on: {
            "close": _vm.closeFlagManagementModal,
            "showFlagAccessModal": _vm.enableFlagAccessModal
          }
        }), _c('flag-access-modal', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.showFlagAccessModal,
            expression: "showFlagAccessModal"
          }],
          attrs: {
            "flag": _vm.sharingFlag
          },
          on: {
            "close": _vm.closeFlagAccessModal
          }
        })];
      },
      proxy: true
    } : null, {
      key: "templates",
      fn: function fn() {
        return [_c('agreements-table', {
          ref: "agreementsTable",
          attrs: {
            "tableData": _vm.templatesTableItems,
            "busy": _vm.templatesTableIsBusy,
            "sortingQueryAction": "templates/setTemplatesTableSortingQuery",
            "getItemsAction": "templates/getTemplates",
            "setTableBusyMutation": "templates/setTemplatesTableBusy",
            "tablePerPageMutation": "templates/setTemplatesTablePerPage",
            "perPage": _vm.agreementsPerPage,
            "showPageNumberOptions": true
          }
        })];
      },
      proxy: true
    }, {
      key: "campaigns",
      fn: function fn() {
        return [_c('campaigns-table', {
          ref: "campaignsTable",
          attrs: {
            "tableData": _vm.campaignsTableItems,
            "busy": _vm.campaignsTableIsBusy,
            "sortingQueryAction": "campaigns/setCampaignsTableSortingQuery",
            "getItemsAction": "campaigns/getCampaigns",
            "setTableBusyMutation": "campaigns/setCampaignsTableBusy",
            "tablePerPageMutation": "campaigns/setCampaignsTablePerPage",
            "perPage": _vm.campaignsPerPage,
            "showPageNumberOptions": true
          }
        })];
      },
      proxy: true
    }, {
      key: "payments",
      fn: function fn() {
        return [_c('payments-table', {
          ref: "paymentsTable",
          attrs: {
            "tableData": _vm.scheduleTableItems,
            "busy": _vm.scheduleTableIsBusy,
            "sortingQueryAction": "products/setScheduleTableSortingQuery",
            "getItemsAction": "products/getScheduleList",
            "setTableBusyMutation": "products/setScheduleTableBusy",
            "tablePerPageMutation": "products/setScheduleTablePerPage",
            "perPage": _vm.schedulePerPage,
            "showPageNumberOptions": true
          }
        })];
      },
      proxy: true
    }, {
      key: "clatTax",
      fn: function fn() {
        return [_c('clat-tax-table', {
          ref: "clatTaxTable",
          attrs: {
            "tableData": _vm.clatTaxTableItems,
            "busy": _vm.clatTaxTableIsBusy,
            "sortingQueryAction": "products/setClatTaxTableSortingQuery",
            "getItemsAction": "products/getClatTaxList",
            "setTableBusyMutation": "products/setClatTaxTableBusy",
            "tablePerPageMutation": "products/setClatTaxTablePerPage",
            "perPage": _vm.clatTaxPerPage,
            "showPageNumberOptions": true
          }
        }, [_vm._v(">")])];
      },
      proxy: true
    }], null, true)
  })], 1)]), _vm.showPaymentPackageModal ? _c('sygni-modal', {
    staticClass: "remove-overflow-y",
    attrs: {
      "isLoading": _vm.isPaymentPackageLoading,
      "header": "Payment Package Details",
      "cancelText": "Cancel",
      "confirmText": "Generate package",
      "size": "medium"
    },
    on: {
      "cancel": _vm.closePaymentPackageModal,
      "confirm": _vm.generatePaymentPackage,
      "close": _vm.closePaymentPackageModal
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        var _vm$$v;

        return [_c('div', {
          staticClass: "sygni-modal-form"
        }, [_c('p', {
          staticClass: "sygni-modal-form__header"
        }, [_vm._v("1. Execute payment package:")]), _c('div', {
          staticClass: "sygni-modal-form__group"
        }, [_c('div', {
          staticClass: "sygni-modal-form__radios"
        }, [_c('sygni-radio', {
          attrs: {
            "group": 'customPaymentDate',
            "radioValue": true,
            "label": "on a custom date"
          },
          model: {
            value: _vm.useCustomPaymentDate,
            callback: function callback($$v) {
              _vm.useCustomPaymentDate = $$v;
            },
            expression: "useCustomPaymentDate"
          }
        }), _c('sygni-radio', {
          attrs: {
            "group": 'customPaymentDate',
            "radioValue": false,
            "label": "at date of payment of each document"
          },
          model: {
            value: _vm.useCustomPaymentDate,
            callback: function callback($$v) {
              _vm.useCustomPaymentDate = $$v;
            },
            expression: "useCustomPaymentDate"
          }
        })], 1), _c('sygni-date-picker', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.useCustomPaymentDate,
            expression: "useCustomPaymentDate"
          }],
          attrs: {
            "format": "YYYY-MM-DD",
            "validation": (_vm$$v = _vm.$v) === null || _vm$$v === void 0 ? void 0 : _vm$$v.customPaymentDate
          },
          model: {
            value: _vm.customPaymentDate,
            callback: function callback($$v) {
              _vm.customPaymentDate = $$v;
            },
            expression: "customPaymentDate"
          }
        })], 1)]), _c('div', {
          staticClass: "sygni-modal-form"
        }, [_c('p', {
          staticClass: "sygni-modal-form__header"
        }, [_vm._v("2. Payment package name and SEPA:")]), _c('div', {
          staticClass: "sygni-modal-form__group"
        }, [_c('div', {
          staticClass: "sygni-modal-form__inputs"
        }, [_c('sygni-input', {
          attrs: {
            "label": "",
            "placeholder": _vm.defaultPaymentPackageName
          },
          model: {
            value: _vm.customPackageName,
            callback: function callback($$v) {
              _vm.customPackageName = $$v;
            },
            expression: "customPackageName"
          }
        })], 1)]), _c('div', {
          staticClass: "sygni-modal-form__row"
        }, [_c('div', {
          staticClass: "sygni-modal-form__row-checkbox"
        }, [_c('sygni-checkbox', {
          staticClass: "secondary",
          attrs: {
            "clickableLabel": true
          },
          model: {
            value: _vm.isSepa,
            callback: function callback($$v) {
              _vm.isSepa = $$v;
            },
            expression: "isSepa"
          }
        }, [_vm._v("SEPA")])], 1)])])];
      },
      proxy: true
    }], null, false, 3418499878)
  }) : _vm._e(), _vm.showClatTaxPaymentPackageModal ? _c('sygni-modal', {
    staticClass: "remove-overflow-y",
    attrs: {
      "isLoading": _vm.isClatTaxPaymentPackageLoading,
      "header": "Payment Package Details",
      "cancelText": "Cancel",
      "confirmText": "Generate package",
      "size": "large"
    },
    on: {
      "cancel": _vm.closeClatTaxPaymentPackageModal,
      "confirm": _vm.generateClatTaxPaymentPackage,
      "close": _vm.closeClatTaxPaymentPackageModal
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        var _vm$$v2, _vm$$v2$clatTaxPackag, _vm$$v3, _vm$$v3$clatTaxPackag, _vm$$v4, _vm$$v4$clatTaxPackag, _vm$selectedBankAccou, _vm$selectedBankAccou2;

        return [_c('div', {
          staticClass: "sygni-modal-form"
        }, [_c('p', {
          staticClass: "sygni-modal-form__header"
        }, [_vm._v("1. Enter payment package name:")]), _c('div', {
          staticClass: "sygni-modal-form__group"
        }, [_c('div', {
          staticClass: "sygni-modal-form__inputs"
        }, [_c('sygni-input', {
          attrs: {
            "label": "",
            "placeholder": _vm.defaultPaymentPackageName
          },
          model: {
            value: _vm.clatTaxPackageModal.customPackageName,
            callback: function callback($$v) {
              _vm.$set(_vm.clatTaxPackageModal, "customPackageName", $$v);
            },
            expression: "clatTaxPackageModal.customPackageName"
          }
        })], 1)])]), _c('div', {
          staticClass: "sygni-modal-form"
        }, [_c('p', {
          staticClass: "sygni-modal-form__header"
        }, [_vm._v("2. Execute payment package:")]), _c('div', {
          staticClass: "sygni-modal-form__group"
        }, [_c('div', {
          staticClass: "sygni-modal-form__radios"
        }, [_c('sygni-radio', {
          attrs: {
            "group": 'clatTaxCustomPaymentDate',
            "radioValue": true,
            "label": "on a custom date"
          },
          model: {
            value: _vm.useCustomClatTaxPaymentDate,
            callback: function callback($$v) {
              _vm.useCustomClatTaxPaymentDate = $$v;
            },
            expression: "useCustomClatTaxPaymentDate"
          }
        }), _c('sygni-radio', {
          attrs: {
            "group": 'clatTaxCustomPaymentDate',
            "radioValue": false,
            "label": "at date of payment of each document"
          },
          model: {
            value: _vm.useCustomClatTaxPaymentDate,
            callback: function callback($$v) {
              _vm.useCustomClatTaxPaymentDate = $$v;
            },
            expression: "useCustomClatTaxPaymentDate"
          }
        })], 1), _c('sygni-date-picker', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.useCustomClatTaxPaymentDate,
            expression: "useCustomClatTaxPaymentDate"
          }],
          attrs: {
            "format": "YYYY-MM-DD",
            "validation": (_vm$$v2 = _vm.$v) === null || _vm$$v2 === void 0 ? void 0 : (_vm$$v2$clatTaxPackag = _vm$$v2.clatTaxPackageModal) === null || _vm$$v2$clatTaxPackag === void 0 ? void 0 : _vm$$v2$clatTaxPackag.customPaymentDate
          },
          model: {
            value: _vm.clatTaxPackageModal.customPaymentDate,
            callback: function callback($$v) {
              _vm.$set(_vm.clatTaxPackageModal, "customPaymentDate", $$v);
            },
            expression: "clatTaxPackageModal.customPaymentDate"
          }
        })], 1)]), _c('div', {
          staticClass: "sygni-modal-form"
        }, [_c('p', {
          staticClass: "sygni-modal-form__header"
        }, [_vm._v("3. Select Tax Office:")]), _c('div', {
          staticClass: "sygni-modal-form__group"
        }, [_c('div', {
          staticClass: "sygni-modal-form__inputs"
        }, [_c('sygni-select', {
          attrs: {
            "label": "",
            "options": _vm.taxOfficeCodes,
            "searchable": true,
            "validation": (_vm$$v3 = _vm.$v) === null || _vm$$v3 === void 0 ? void 0 : (_vm$$v3$clatTaxPackag = _vm$$v3.clatTaxPackageModal) === null || _vm$$v3$clatTaxPackag === void 0 ? void 0 : _vm$$v3$clatTaxPackag.taxOffice
          },
          model: {
            value: _vm.clatTaxPackageModal.taxOffice,
            callback: function callback($$v) {
              _vm.$set(_vm.clatTaxPackageModal, "taxOffice", $$v);
            },
            expression: "clatTaxPackageModal.taxOffice"
          }
        })], 1)])]), _c('div', {
          staticClass: "sygni-modal-form"
        }, [_c('p', {
          staticClass: "sygni-modal-form__header"
        }, [_vm._v("4. Select Your Bank Account:")]), _c('div', {
          staticClass: "sygni-modal-form__group"
        }, [_c('div', {
          staticClass: "sygni-modal-form__inputs"
        }, [_c('sygni-select', {
          attrs: {
            "label": "",
            "options": _vm.bankAccountOptions,
            "validation": (_vm$$v4 = _vm.$v) === null || _vm$$v4 === void 0 ? void 0 : (_vm$$v4$clatTaxPackag = _vm$$v4.clatTaxPackageModal) === null || _vm$$v4$clatTaxPackag === void 0 ? void 0 : _vm$$v4$clatTaxPackag.bankAccount
          },
          model: {
            value: _vm.clatTaxPackageModal.bankAccount,
            callback: function callback($$v) {
              _vm.$set(_vm.clatTaxPackageModal, "bankAccount", $$v);
            },
            expression: "clatTaxPackageModal.bankAccount"
          }
        }), _c('div', {
          staticClass: "sygni-modal-form__container"
        }, [_c('label', {
          staticClass: "debtor-data mb-3"
        }, [_c('span', {
          staticClass: "debtor-data__title"
        }, [_vm._v(" " + _vm._s(_vm.$t('marketplace.debtor_account_name')) + ": ")]), _vm._v(" " + _vm._s(((_vm$selectedBankAccou = _vm.selectedBankAccount) === null || _vm$selectedBankAccou === void 0 ? void 0 : _vm$selectedBankAccou.name) || '') + " ")]), _c('label', {
          staticClass: "debtor-data mb-3"
        }, [_c('span', {
          staticClass: "debtor-data__title"
        }, [_vm._v(" " + _vm._s(_vm.$t('marketplace.debtor_account_currency')) + ": ")]), _vm._v(" " + _vm._s(((_vm$selectedBankAccou2 = _vm.selectedBankAccount) === null || _vm$selectedBankAccou2 === void 0 ? void 0 : _vm$selectedBankAccou2.currency) || '') + " ")])])], 1)])])];
      },
      proxy: true
    }], null, false, 3283662851)
  }) : _vm._e(), _c('sygni-modal', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showBulkDetailsModal,
      expression: "showBulkDetailsModal"
    }],
    attrs: {
      "header": "Update payment details",
      "size": "large",
      "isLoading": _vm.isBulkModalLoading,
      "disabled": !((_vm$bulkDetailsData = _vm.bulkDetailsData) !== null && _vm$bulkDetailsData !== void 0 && _vm$bulkDetailsData.paymentStatus) && ((_vm$bulkDetailsData2 = _vm.bulkDetailsData) === null || _vm$bulkDetailsData2 === void 0 ? void 0 : (_vm$bulkDetailsData2$ = _vm$bulkDetailsData2.toUpdate) === null || _vm$bulkDetailsData2$ === void 0 ? void 0 : _vm$bulkDetailsData2$.paymentStatus),
      "confirmText": _vm.isPaymentManager ? 'Save changes' : '',
      "cancelText": "Close"
    },
    on: {
      "cancel": _vm.closeBulkDetailsModal,
      "close": _vm.closeBulkDetailsModal,
      "confirm": _vm.updateBulkPaymentData
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        var _vm$bulkDetailsData3, _vm$bulkDetailsData4, _vm$bulkDetailsData4$, _vm$bulkDetailsData5, _vm$bulkDetailsData6, _vm$bulkDetailsData6$, _vm$bulkDetailsData7, _vm$bulkDetailsData8, _vm$bulkDetailsData8$;

        return [_c('div', {
          staticClass: "options"
        }, [_c('sygni-checkbox', {
          staticClass: "secondary inline",
          attrs: {
            "label": "UPDATE PAYMENT STATUS"
          },
          model: {
            value: _vm.bulkDetailsData.toUpdate.paymentStatus,
            callback: function callback($$v) {
              _vm.$set(_vm.bulkDetailsData.toUpdate, "paymentStatus", $$v);
            },
            expression: "bulkDetailsData.toUpdate.paymentStatus"
          }
        }), _c('sygni-checkbox', {
          staticClass: "secondary inline",
          attrs: {
            "label": "UPDATE PAYMENT BOOK DATE"
          },
          model: {
            value: _vm.bulkDetailsData.toUpdate.paymentBookDate,
            callback: function callback($$v) {
              _vm.$set(_vm.bulkDetailsData.toUpdate, "paymentBookDate", $$v);
            },
            expression: "bulkDetailsData.toUpdate.paymentBookDate"
          }
        }), _c('sygni-checkbox', {
          staticClass: "secondary inline",
          attrs: {
            "label": "UPDATE DESCRIPTION"
          },
          model: {
            value: _vm.bulkDetailsData.toUpdate.description,
            callback: function callback($$v) {
              _vm.$set(_vm.bulkDetailsData.toUpdate, "description", $$v);
            },
            expression: "bulkDetailsData.toUpdate.description"
          }
        })], 1), _c('div', {
          staticClass: "form"
        }, [_c('div', {
          staticClass: "form__row"
        }, [_c('div', {
          staticClass: "form__col"
        }, [_c('sygni-select', {
          staticClass: "secondary",
          attrs: {
            "label": "PAYMENT STATUS",
            "options": _vm.paymentStatuses,
            "value": (_vm$bulkDetailsData3 = _vm.bulkDetailsData) === null || _vm$bulkDetailsData3 === void 0 ? void 0 : _vm$bulkDetailsData3.paymentStatus,
            "allow-empty": false,
            "disabled": !_vm.isPaymentManager || !((_vm$bulkDetailsData4 = _vm.bulkDetailsData) !== null && _vm$bulkDetailsData4 !== void 0 && (_vm$bulkDetailsData4$ = _vm$bulkDetailsData4.toUpdate) !== null && _vm$bulkDetailsData4$ !== void 0 && _vm$bulkDetailsData4$.paymentStatus)
          },
          on: {
            "input": _vm.setBulkPaymentStatus
          }
        })], 1), _c('div', {
          staticClass: "form__col"
        }, [_c('sygni-date-picker', {
          attrs: {
            "label": "PAYMENT BOOK DATE",
            "value": (_vm$bulkDetailsData5 = _vm.bulkDetailsData) === null || _vm$bulkDetailsData5 === void 0 ? void 0 : _vm$bulkDetailsData5.paymentBookDate,
            "disabled": !_vm.isPaymentManager || !((_vm$bulkDetailsData6 = _vm.bulkDetailsData) !== null && _vm$bulkDetailsData6 !== void 0 && (_vm$bulkDetailsData6$ = _vm$bulkDetailsData6.toUpdate) !== null && _vm$bulkDetailsData6$ !== void 0 && _vm$bulkDetailsData6$.paymentBookDate)
          },
          on: {
            "change": _vm.setBulkPaymentBookDate
          }
        })], 1)]), _c('div', {
          staticClass: "form__row"
        }, [_c('div', {
          staticClass: "form__col"
        }, [_c('sygni-text-area', {
          attrs: {
            "label": "DESCRIPTION",
            "value": (_vm$bulkDetailsData7 = _vm.bulkDetailsData) === null || _vm$bulkDetailsData7 === void 0 ? void 0 : _vm$bulkDetailsData7.description,
            "disabled": !_vm.isPaymentManager || !((_vm$bulkDetailsData8 = _vm.bulkDetailsData) !== null && _vm$bulkDetailsData8 !== void 0 && (_vm$bulkDetailsData8$ = _vm$bulkDetailsData8.toUpdate) !== null && _vm$bulkDetailsData8$ !== void 0 && _vm$bulkDetailsData8$.description)
          },
          on: {
            "input": _vm.setBulkDescription
          }
        })], 1)])])];
      },
      proxy: true
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }