var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "networks-management-modal"
  }, [_c('div', {
    staticClass: "networks-management-modal__bg",
    on: {
      "click": function click($event) {
        return _vm.$emit('close');
      }
    }
  }), _c('div', {
    staticClass: "networks-management-modal__box"
  }, [_c('div', {
    class: ['networks-management-modal__loader', _vm.isLoading ? 'active' : '']
  }, [_c('div', {
    staticClass: "spinner"
  }, [_c('b-spinner')], 1)]), _c('div', {
    staticClass: "networks-management-modal__actions"
  }, [_c('h2', {
    staticClass: "networks-management-modal__header"
  }, [_vm._v("Networks")]), _c('button', {
    staticClass: "networks-management-modal__action networks-management-modal__action--close",
    on: {
      "click": function click($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v("Close")])]), _c('div', {
    staticClass: "networks-management-modal__inner"
  }, [_c('div', {
    staticClass: "networks-management-modal__body"
  }, [_vm.networks.length == 0 ? _c('p', {
    staticClass: "networks-management-modal__desc"
  }, [_vm._v("There are no networks to display")]) : _c('div', {
    staticClass: "networks-management-modal__options"
  }, _vm._l(_vm.networks, function (network) {
    var _vm$brokerData, _vm$brokerData$broker;

    return _c('div', {
      key: network.id,
      staticClass: "networks-management-modal__option"
    }, [_c('div', {
      staticClass: "networks-management-modal__option-text"
    }, [_c('span', {
      staticClass: "networks-management-modal__option-text-content"
    }, [_vm._v(_vm._s(network.name))]), _vm.showShareIcon(network) ? _c('img', {
      staticClass: "networks-management-modal__option-icon",
      attrs: {
        "src": _vm._f("getIcon")('Investor'),
        "alt": ""
      }
    }) : _vm._e(), (_vm$brokerData = _vm.brokerData) !== null && _vm$brokerData !== void 0 && (_vm$brokerData$broker = _vm$brokerData.brokerNetworkIds) !== null && _vm$brokerData$broker !== void 0 && _vm$brokerData$broker.includes(network.id) ? _c('div', {
      staticClass: "networks-management-modal__coordinator"
    }, [_c('sygni-checkbox', {
      staticClass: "secondary",
      attrs: {
        "label": "Coordinator",
        "value": _vm.isCoordinator(network.id),
        "remove-watcher": true
      },
      on: {
        "changedValue": function changedValue($event) {
          return _vm.toggleCoordinator(network);
        }
      }
    })], 1) : _vm._e()]), _c('div', {
      staticClass: "networks-management-modal__option-btn",
      on: {
        "click": function click($event) {
          return _vm.openEditMode(network);
        }
      }
    }, [_vm._v("Edit")])]);
  }), 0)])]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.editedNetworkId,
      expression: "!editedNetworkId"
    }],
    staticClass: "networks-management-modal__footer"
  }, [_c('p', {
    staticClass: "networks-management-modal__header"
  }, [_vm._v("Add")]), _c('div', {
    staticClass: "networks-management-modal__footer-inner"
  }, [_c('div', {
    staticClass: "networks-management-modal__input"
  }, [_c('sygni-input', {
    ref: "newNetworkInput",
    attrs: {
      "label": "NEW NETWORK",
      "validation": _vm.$v.newNetwork.name
    },
    on: {
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.addNewNetwork.apply(null, arguments);
      }
    },
    model: {
      value: _vm.newNetwork.name,
      callback: function callback($$v) {
        _vm.$set(_vm.newNetwork, "name", $$v);
      },
      expression: "newNetwork.name"
    }
  })], 1), _c('sygni-rect-button', {
    staticClass: "filled black",
    on: {
      "click": _vm.addNewNetwork
    }
  }, [_vm._v("Add")])], 1)]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.editedNetworkId,
      expression: "editedNetworkId"
    }],
    staticClass: "networks-management-modal__footer"
  }, [_c('div', {
    staticClass: "networks-management-modal__header"
  }, [_c('span', [_vm._v("Edit")]), _c('span', {
    staticClass: "networks-management-modal__close",
    on: {
      "click": _vm.closeEditMode
    }
  }, [_vm._v("Close")])]), _c('div', {
    staticClass: "networks-management-modal__footer-inner"
  }, [_c('div', {
    staticClass: "networks-management-modal__input"
  }, [_c('sygni-input', {
    ref: "newNetworkInput",
    attrs: {
      "label": "EDIT NETWORK",
      "validation": _vm.$v.editedNetwork.name
    },
    on: {
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.putNewNetwork.apply(null, arguments);
      }
    },
    model: {
      value: _vm.editedNetwork.name,
      callback: function callback($$v) {
        _vm.$set(_vm.editedNetwork, "name", $$v);
      },
      expression: "editedNetwork.name"
    }
  })], 1), _c('sygni-rect-button', {
    staticClass: "filled black",
    on: {
      "click": _vm.putNewNetwork
    }
  }, [_vm._v("Edit")])], 1)])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }